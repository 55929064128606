import React, { useState } from "react"
import { Spin } from "antd"
import PageRoot from "../../../../components/PageRoot"
import ContainerWithPadding from "../../../../components/ContainerWithPadding"
import AddPaymentForm from "../../../../components/forms/AddPaymentMethodForm"
import { navigate } from "gatsby"
import {
  translatePath,
  uiHandleError,
  uiHandleSuccess,
} from "../../../../utils"
import { useTranslation } from "react-i18next"
import useBraintree from "../../../../hooks/useBraintree"

const CreateBillingProfilePage = () => {
  const { t } = useTranslation()
  const { addUserPaymentMethod } = useBraintree()
  const [loading, setLoading] = useState(false)
  const handleBillingFormSubmit = async values => {
    setLoading(true)
    try {
      await addUserPaymentMethod(values)
      uiHandleSuccess({
        message: t("message:paymentMethodAdded"),
        action: () => {
          navigate(translatePath(`/me/billing/payment-method/list`))
        },
      })
    } catch (error) {
      console.log(error)
      uiHandleError({ error })
    }

    setLoading(false)
  }

  return (
    <PageRoot title={t("label:addPaymentMethod")} showTitle>
      <ContainerWithPadding size={"small"}>
        <Spin spinning={loading}>
          <AddPaymentForm onFinish={handleBillingFormSubmit} />
        </Spin>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default CreateBillingProfilePage
