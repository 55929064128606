import React, { useEffect, useState } from "react"
import { Button, Form, Spin } from "antd"
import dropin from "braintree-web-drop-in"
import { uiHandleError } from "../../utils"
import usePaymentFormItems from "./usePaymentFormItems"
import { useTranslation } from "react-i18next"
import useTosAndPrivacyFormItems from "./useTosAndPrivacyFormItems"
import useBraintree from "../../hooks/useBraintree"

const AddPaymentForm = ({ onFinish: externalFinish }) => {
  const { getToken } = useBraintree()

  const { t } = useTranslation()

  const paymentFormItems = usePaymentFormItems()
  const tosAndPrivacyFormItems = useTosAndPrivacyFormItems()

  const [dropinInstance, setDropinInstance] = useState(null)
  const [error, setError] = useState(null)
  const [loadingDropin, setLoadingDropin] = useState(true)

  const initPaymentForm = async () => {
    try {
      const token = await getToken()
      dropin.create(
        {
          authorization: token,
          container: "#dropin-container",
          locale: process.env.GATSBY_LANG,
          card: {},
          paypal: {
            flow: "vault",
          },
        },
        (createError, instance) => {
          setDropinInstance(instance)
          setError(createError)
          setLoadingDropin(false)
        }
      )
    } catch (error) {
      console.error({ error })
      setError(error)
    }
  }

  useEffect(() => {
    if (!dropinInstance) {
      initPaymentForm()
    }

    return () => {
      if (dropinInstance) {
        dropinInstance.teardown()
      }
    }
  }, [])

  useEffect(() => {
    error && uiHandleError({ error })
  }, [error])

  const onFinish = values => {
    dropinInstance.requestPaymentMethod(
      async (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          // TODO handle error
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        externalFinish({ ...values, paymentMethodNonce: payload.nonce })
      }
    )
  }

  return (
    <Form onFinish={onFinish} layout={"vertical"}>
      {paymentFormItems}

      <Spin spinning={loadingDropin}>
        <div id="dropin-container" />
        <br />
      </Spin>

      {tosAndPrivacyFormItems}

      <div className="form-submit-button-wrapper">
        <Form.Item>
          <Button
            size="large"
            className="green"
            disabled={!dropinInstance}
            type="primary"
            htmlType="submit"
          >
            {t("button:add")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default AddPaymentForm
