import React from "react"
import { Checkbox, Form } from "antd"
import { useTranslation } from "react-i18next"

const usePaymentFormItems = () => {
  const { t } = useTranslation()
  return (
    <>
      <Form.Item
        name="tos"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: t("form:error.acceptTos"),
          },
        ]}
      >
        <Checkbox>
          {t("form:label.accept")}
          <a href="/tos" target="_blank">
            <span> {t("form:label.tos")}</span>
          </a>
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="privacy"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: t("form:error.acceptPrivacy"),
          },
        ]}
      >
        <Checkbox>
          {t("form:label.accept")}
          <a href="/privacy" target="_blank">
            <span> {t("form:label.privacyPolicy")}</span>
          </a>
        </Checkbox>
      </Form.Item>
    </>
  )
}

export default usePaymentFormItems
